<template>
  <el-card class="jogador-card" shadow="hover">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Carregando dados...</p>
    </div>
    <div class="jogador-info" v-else>
      <div
        ref="statsSection"
        :class="['stats-section', { 'share-mode': compartilhando }]"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h4>{{ dados.partida.campo.nome }} {{ dataPartida }}</h4>
          </el-col>
          <el-col :span="12">
            <img
              v-if="dados.partida.time1.foto"
              :src="dados.partida.time1.foto"
              alt="Foto do time1"
              class="time-foto"
            />
            <img v-else :src="semLogo1" alt="Foto do time1" class="time-foto" />
            <h5>{{ dados.partida.time1.nome }}</h5>
          </el-col>
          <el-col :span="12">
            <img
              v-if="dados.partida.time2.foto"
              :src="dados.partida.time2.foto"
              alt="Foto do time2"
              class="time-foto"
            />
            <img v-else :src="semLogo2" alt="Foto do time2" class="time-foto" />
            <h5>{{ dados.partida.time2.nome }}</h5>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <img
              v-if="dados.jogador.foto"
              :src="dados.jogador.foto"
              alt="Foto do jogador"
              class="jogador-foto"
            />
            <img
              v-else
              :src="userFoto"
              alt="Foto do jogador"
              class="jogador-foto"
            />
            <h2>{{ dados.jogador.nome }}</h2>
            <p>{{ numero }} - {{ posicao }}</p>
            <p>{{ dados.time.nome }}</p>
          </el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Metros percorridos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.distanciaPercorrida
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Chutes (alvo):</el-col>
          <el-col :span="12" class="stat-value">{{ dados.chutesAoGol }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Chutes:</el-col>
          <el-col :span="12" class="stat-value">{{ dados.totalChutes }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Bolas recuperadas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.bolasRecuperadas
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes recebidos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesRecebidos
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes corretos:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesCertos
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Passes errados:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.passesErrados
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Assistências para gol:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.assistenciasAGols
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Gols:</el-col>
          <el-col :span="12" class="stat-value">{{ dados.gols }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Calorias Perdidas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.caloriasPerdidas
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20">
          <el-col :span="12" class="stat-label">Score na partida:</el-col>
          <el-col :span="12" class="stat-value">{{ dados.score }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20" v-if="posicao === 'goleiro'">
          <el-col :span="12" class="stat-label">Defesas:</el-col>
          <el-col :span="12" class="stat-value">{{
            dados.defesasGoleiro
          }}</el-col>
        </el-row>
        <el-row class="statistic-row" :gutter="20" v-show="compartilhando">
          <el-col :span="24" class="space-betwen">
            <img :src="logo" alt="logo aite" style="width: 250px" />
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <el-button type="success" icon="el-icon-share" @click="shareStats"
            >Compartilhar Estatísticas</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="midias">
            <h2>Mídias</h2>
            <div v-if="midias.length === 0">Nenhuma mídia disponível.</div>
            <div v-else>
              <el-row
                :gutter="20"
                v-for="(media, index) in midias"
                :key="index"
              >
                <el-col :span="24">
                  <template v-if="isImage(media.nomeArquivo)">
                    <img
                      :src="'data:image/png;base64,' + media.arquivo"
                      alt="Mídia"
                      class="media-image"
                    />
                  </template>
                  <template v-else-if="isVideo(media.nomeArquivo)">
                    <video controls class="media-video">
                      <source
                        :src="'data:video/mp4;base64,' + media.arquivo"
                        type="video/mp4"
                      />
                      Seu navegador não suporta o elemento de vídeo.
                    </video>
                  </template>
                  <template v-else>
                    <div>Formato de mídia não suportado.</div>
                  </template>
                  <div class="media-actions">
                    <el-button
                      type="primary"
                      size="mini"
                      icon="el-icon-download"
                      @click="downloadMedia(media)"
                      >Baixar</el-button
                    >
                    <el-button
                      type="success"
                      size="mini"
                      icon="el-icon-share"
                      @click="shareMedia(media)"
                      >Compartilhar</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import html2canvas from 'html2canvas'
import relatorioJogadorServices from '@/services/relatorioJogadorServices'
import userFoto from '@/assets/user.png'
import logo from '@/assets/logo.png'
import semLogo1 from '@/assets/sem-logo-1.png'
import semLogo2 from '@/assets/sem-logo-2.png'

export default {
  data() {
    return {
      id: null,
      dados: {},
      logo,
      semLogo1,
      baseUrl: process.env.VUE_APP_API_URL,
      semLogo2,
      compartilhando: false,
      userFoto: userFoto,
      isLoading: true,
      midias: [],
    }
  },
  computed: {
    numero() {
      return this.dados?.partida?.jogadoresPartida.find(
        (j) => j.jogadorId === this.$route.params.id
      )?.numero
    },
    posicao() {
      return this.dados?.partida?.jogadoresPartida.find(
        (j) => j.jogadorId === this.$route.params.id
      )?.posicao
    },
    dataPartida() {
      return this.dados?.partida
        ? this.formatarDataBrasil(this.dados?.partida.data) +
            ' ' +
            this.dados?.partida.horaInicio
        : ''
    },
  },
  async mounted() {
    try {
      this.dados = await relatorioJogadorServices.findById(
        this.$route.params.id,
        this.$route.params.idPartida
      )
      this.midias = await relatorioJogadorServices.findMidiasById(
        this.$route.params.id,
        this.$route.params.idPartida
      )
    } catch (e) {
      console.error(e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    isImage(nomeArquivo) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif']
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase()
      return imageExtensions.includes(fileExtension)
    },
    formatarDataBrasil(dataIso) {
      if (!dataIso) return ''
      const [ano, mes, dia] = dataIso.split('-')
      return `${dia}/${mes}/${ano}`
    },
    isVideo(nomeArquivo) {
      const videoExtensions = ['mp4', 'avi', 'mov']
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase()
      return videoExtensions.includes(fileExtension)
    },
    downloadMedia(media) {
      const link = document.createElement('a')
      const mimeType = this.isImage(media.nomeArquivo)
        ? 'image/png'
        : 'video/mp4'
      link.href = `data:${mimeType};base64,${media.arquivo}`
      link.download = media.nomeArquivo
      link.click()
    },
    async shareMedia(media) {
      if (navigator.share) {
        const mimeType = this.isImage(media.nomeArquivo)
          ? 'image/png'
          : 'video/mp4'
        const blob = await fetch(
          `data:${mimeType};base64,${media.arquivo}`
        ).then((res) => res.blob())
        const file = new File([blob], media.nomeArquivo, { type: mimeType })

        try {
          await navigator.share({
            title: 'Confira esta mídia',
            text: 'Estou compartilhando esta mídia com você!',
            files: [file],
          })
          console.log('Compartilhamento realizado com sucesso!')
        } catch (error) {
          console.error('Erro ao compartilhar:', error)
        }
      } else {
        alert('O compartilhamento não é suportado neste dispositivo.')
      }
    },
    async shareStats() {
      this.compartilhando = true
      setTimeout(async () => {
        try {
          const statsSection = this.$refs.statsSection
          const canvas = await html2canvas(statsSection)

          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          )
          const file = new File([blob], 'estatisticas.png', {
            type: 'image/png',
          })

          if (navigator.share) {
            await navigator.share({
              title: 'Estatísticas do Jogador',
              text: 'Confira as estatísticas do jogador!',
              files: [file],
            })
            console.log('Estatísticas compartilhadas com sucesso!')
          } else {
            alert('Compartilhamento não suportado neste dispositivo.')
          }
        } catch (error) {
          console.error('Erro ao compartilhar as estatísticas:', error)
          alert('Não foi possível compartilhar as estatísticas.')
        } finally {
          this.compartilhando = false
        }
      }, 1000)
    },
  },
}
</script>

<style scoped>
<style scoped>
/* Estilos gerais */
.jogador-card {
  max-width: 900px;
  margin: 20px auto;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

/* Seção de informações do jogador */
.jogador-info {
  text-align: center;
}

/* Estilos para a seção de estatísticas */
.stats-section {
  padding: 20px;
  margin: 20px 0;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.stats-section.share-mode {
  width: 1100px;
  height: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

/* Estilos para as fotos */
.time-foto,
.jogador-foto {
  width: 100px; /* Tamanho padrão para mobile */
  height: 100px; /* Tamanho padrão para mobile */
  border-radius: 50%;
  object-fit: cover; /* Garante que a imagem cubra o espaço sem distorção */
  border: 3px solid #fff; /* Borda branca para destacar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner arredondado */
}

/* Estilos para os textos */
h2 {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

h4 {
  font-size: 20px;
  margin: 10px 0;
  color: #555;
}

h5 {
  font-size: 18px;
  margin: 10px 0;
  color: #666;
}

p {
  font-size: 16px;
  color: #777;
  margin: 5px 0;
}

/* Estilos para as estatísticas */
.statistic-row {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stat-label {
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.stat-value {
  font-size: 16px;
  color: #333;
  text-align: right;
}

/* Estilos para as mídias */
.midias {
  margin-top: 30px;
}

.media-image,
.media-video {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.media-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Botões */
.el-button {
  margin: 5px;
}

/* Estilos para telas maiores (desktop) */
@media (min-width: 768px) {
  .jogador-card {
    padding: 40px;
  }

  .jogador-foto {
    width: 200px;
    height: 200px;
  }

  .time-foto {
    width: 100px;
    height: 100px;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  p {
    font-size: 18px;
  }

  .stat-label,
  .stat-value {
    font-size: 18px;
  }

  .media-image,
  .media-video {
    max-width: 600px;
  }
}
</style>
