<template>
  <div class="partidas-container">
    <h1 class="page-title">Minhas Últimas Partidas</h1>

    <!-- Tabela para desktop -->
    <el-table
      :data="partidas"
      border
      stripe
      style="width: 100%"
      class="partidas-table hidden-sm-and-down"
    >
      <el-table-column
        prop="n1"
        label="Time 1"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="n2"
        label="Time 2"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="campo"
        label="Campo"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="data"
        label="Data da Partida"
        align="center"
        header-align="center"
        :formatter="formatarData"
      />
      <el-table-column
        label="Ações"
        align="center"
        header-align="center"
        width="150"
      >
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="irParaDadosPartida(scope.row.id)"
          >
            Detalhes
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Cards para mobile -->
    <div class="partidas-cards hidden-md-and-up">
      <el-card
        v-for="partida in partidas"
        :key="partida.id"
        class="partida-card"
      >
        <div class="card-content">
          <div class="card-item"><strong>Time 1:</strong> {{ partida.n1 }}</div>
          <div class="card-item"><strong>Time 2:</strong> {{ partida.n2 }}</div>
          <div class="card-item">
            <strong>Campo:</strong> {{ partida.campo }}
          </div>
          <div class="card-item">
            <strong>Data:</strong> {{ formatarData(null, null, partida.data) }}
          </div>
          <div class="card-item">
            <el-button
              type="primary"
              size="small"
              @click="irParaDadosPartida(partida.id)"
            >
              Detalhes
            </el-button>
          </div>
        </div>
      </el-card>
      <el-card v-if="!partidas.length" style="text-align: center">
        <span>Sem dados</span>
      </el-card>
    </div>
  </div>
</template>
<script>
import partidaServices from '@/services/partidaService'

export default {
  name: 'HomeView',
  data() {
    return {
      partidas: [], // Lista de partidas
    }
  },
  methods: {
    /**
     * Formata a data no formato DD/MM/YYYY.
     * @param {Object} row - Linha da tabela.
     * @param {Object} column - Coluna da tabela.
     * @param {String} cellValue - Valor da célula (data no formato YYYY-MM-DD).
     * @returns {String} Data formatada.
     */
    formatarData(row, column, cellValue) {
      if (!cellValue) return ''
      const [year, month, day] = cellValue.split('-')
      return `${day}/${month}/${year}`
    },

    /**
     * Navega para a página de detalhes da partida.
     * @param {Number} id - ID da partida.
     */
    irParaDadosPartida(id) {
      this.$router.push(`/dadosPartida/${id}`)
    },
  },
  async mounted() {
    try {
      this.$emit('ativarLoad')
      const response = await partidaServices.ultimaspartidas()
      this.partidas = response
    } catch (error) {
      console.error('Erro ao carregar partidas:', error)
      this.$message.error('Não foi possível carregar as partidas.')
    } finally {
      this.$emit('desativarLoad')
    }
  },
}
</script>
<style scoped>
.partidas-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Estilos da tabela (desktop) */
.partidas-table {
  margin-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Estilos dos cards (mobile) */
.partidas-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.partida-card {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 16px;
}

.card-item {
  margin-bottom: 12px;
  font-size: 14px;
}

.card-item:last-child {
  margin-bottom: 0;
}

.card-item strong {
  display: inline-block;
  width: 80px;
  color: #555;
}

/* Responsividade */
@media (max-width: 768px) {
  .page-title {
    font-size: 20px;
  }

  .hidden-sm-and-down {
    display: none; /* Esconde a tabela em telas pequenas */
  }

  .hidden-md-and-up {
    display: block; /* Mostra os cards em telas pequenas */
  }
}

@media (min-width: 769px) {
  .hidden-md-and-up {
    display: none; /* Esconde os cards em telas grandes */
  }
}
</style>
