<template>
  <div id="app">
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Carregando dados...</p>
    </div>
    <nav class="navbar">
      <div class="navbar-left">
        <button class="menu-button">
          <i class="fas fa-bars"></i>
        </button>
        <!-- Logo com borda e redirecionamento -->
        <img
          src="@/assets/logo.png"
          alt="Logo"
          class="navbar-logo"
          @click="goHome"
        />
      </div>
      <div class="navbar-right">
        <!-- Verificar se o usuário está logado -->
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <img :src="userPhoto" alt="Foto do Usuário" class="user-photo" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="userLogged && (roleAdminTime || roleAdmin)"
              command="times"
              >Lista de Times</el-dropdown-item
            >
            <el-dropdown-item v-if="userLogged && roleAdmin" command="campos"
              >Lista de Campos</el-dropdown-item
            >
            <el-dropdown-item
              v-if="userLogged && (roleAdminTime || roleAdmin)"
              command="partidas"
              >Lista de Partidas</el-dropdown-item
            >
            <el-dropdown-item v-if="userLogged" command="home"
              >Minhas Partidas</el-dropdown-item
            >
            <el-dropdown-item v-if="userLogged && roleAdmin" command="usuarios"
              >Usuarios</el-dropdown-item
            >
            <el-dropdown-item v-if="userLogged" command="dadosUsuario"
              >Dados Cadastrais</el-dropdown-item
            >
            <el-dropdown-item v-if="userLogged" command="logout"
              >Logout</el-dropdown-item
            >
            <el-dropdown-item v-else command="login">Login</el-dropdown-item>
            <el-dropdown-item command="">Inicio</el-dropdown-item>
            <el-dropdown-item command="politicas"
              >Politicas de privacidade</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>
    <router-view @ativarLoad="ativarLoad" @desativarLoad="desativarLoad"/>
  </div>
</template>

<script>
export default {
  computed: {
    roleAdmin() {
      return this.userLogged
        ? localStorage.getItem('role')?.includes('ROLE_ADM')
        : false
    },
    roleAdminTime() {
      return this.userLogged
        ? localStorage.getItem('role')?.includes('ROLE_TIME')
        : false
    },
  },
  data() {
    return {
      userLogged: !!localStorage.getItem('user'),
      userPhoto: '',
      isLoading: false,
    }
  },
  mounted() {
    this.handlerFotoUser()
    setInterval(this.handlerFotoUser, 2000)
    setInterval(this.desativarLoad, 6000)
  },
  methods: {
    ativarLoad() {
      this.isLoading = true
    },
    desativarLoad() {
      this.isLoading = false
    },
    handlerFotoUser() {
      this.userLogged = !!localStorage.getItem('user')
      const userPothoLocal = localStorage.getItem('foto')
      this.userPhoto =
        this.userLogged && userPothoLocal && userPothoLocal.length > 5
          ? userPothoLocal
          : require('@/assets/user.png')
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout()
      } else if (command === 'login') {
        this.login()
      } else {
        this.$router.push('/' + command)
      }
    },
    logout() {
      localStorage.removeItem('user')
      localStorage.removeItem('foto')
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      this.$router.push('/')
      window.location.reload()
    },
    login() {
      this.$router.push('/login')
    },
    goHome() {
      this.$router.push('/')
    },
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.menu-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.navbar-logo {
  width: 100px;
  background-color: white; /* Fundo branco ao redor do logo */
  border: 2px solid green; /* Borda verde */
  border-radius: 50px; /* Borda arredondada */
  cursor: pointer;
  object-fit: contain; /* Garante que o logo seja contido corretamente */
  padding: 10px; /* Espaçamento interno */
}

.navbar-right .user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  background-color: white; /* Fundo branco ao redor do logo */
}

.el-dropdown-menu {
  background-color: #fff;
  color: #333;
}

.el-dropdown-item {
  padding: 10px;
}

/* Overlay de carregamento */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-overlay p {
  margin-top: 15px;
  font-size: 18px;
  color: #333;
}

</style>
