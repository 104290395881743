<template>
  <div class="partidas-container">
    <!-- Tabela para desktop -->
    <h3 style="text-align: center; margin-bottom: 15px">
      Lista de Partidas Marcadas
    </h3>
    <el-table :data="tableData" stripe class="hidden-sm-and-down">
      <el-table-column label="Partidas">
        <template slot-scope="scope">
          {{ scope.row.time1.nome }} x {{ scope.row.time2.nome }}
        </template>
      </el-table-column>
      <el-table-column label="Campo" prop="campo.nome" />
      <el-table-column label="Cidade" prop="campo.cidade" />
      <el-table-column label="Estado" prop="campo.estado" />
      <el-table-column label="Data" :formatter="formatarData" prop="data" />
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.sincronizada"
            @click="editItem(scope.row)"
            size="mini"
            type="primary"
          >
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button @click="visualizar(scope.row)" size="mini" type="primary">
            <i class="el-icon-view"></i>
          </el-button>
          <el-button @click="dados(scope.row)" size="mini" type="primary">
            <i class="el-icon-data-board"></i>
          </el-button>
          <el-button @click="excluir(scope.row)" size="mini" type="danger">
            <i class="el-icon-close"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Cards para mobile -->
    <div class="partidas-cards hidden-md-and-up">
      <el-card v-for="item in tableData" :key="item.id" class="partida-card">
        <div class="card-content">
          <div class="card-header">
            <span class="partida-nome"
              >{{ item.time1.nome }} x {{ item.time2.nome }}</span
            >
            <span class="partida-data">{{
              formatarData(null, null, item.data)
            }}</span>
          </div>
          <div class="card-header">
            <span class="partida-data">{{ item.campo.nome }}</span>
            <span class="partida-data">{{
              item.campo.cidade + ' - ' + item.campo.estado
            }}</span>
          </div>
          <div class="card-actions">
            <el-button
              :disabled="item.sincronizada"
              @click="editItem(item)"
              size="mini"
              type="primary"
            >
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button @click="visualizar(item)" size="mini" type="primary">
              <i class="el-icon-view"></i>
            </el-button>
            <el-button @click="dados(item)" size="mini" type="primary">
              <i class="el-icon-data-board"></i>
            </el-button>
            <el-button @click="excluir(item)" size="mini" type="danger">
              <i class="el-icon-close"></i>
            </el-button>
          </div>
        </div>
      </el-card>
      <el-card v-if="!tableData.length" style="text-align: center">
        <span>Sem dados</span>
      </el-card>
    </div>

    <!-- Botão de adicionar -->
    <el-button
      class="add-button"
      @click="addItem"
      type="primary"
      icon="el-icon-plus"
    ></el-button>

    <!-- Modal de detalhes -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Detalhes da Partida"
      width="90%"
    >
      <el-form label-position="top" :model="partida" class="partida-form">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Campo">
              <el-input
                v-model="partida.campo.nome"
                placeholder="Campo"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Time 1">
              <el-input
                v-model="partida.time1.nome"
                placeholder="Time 1"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Time 2">
              <el-input
                v-model="partida.time2.nome"
                placeholder="Time 2"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cor Time 1">
              <el-input
                v-model="partida.corTime1"
                placeholder="Cor Time 1"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cor Time 2">
              <el-input
                v-model="partida.corTime2"
                placeholder="Cor Time 2"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Data da Partida">
              <el-input
                v-model="partida.data"
                placeholder="Data da Partida"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Hora de Início">
              <el-input
                v-model="partida.horaInicio"
                placeholder="Hora de Início"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Hora de Fim">
              <el-input
                v-model="partida.horaFim"
                placeholder="Hora de Fim"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Administrador da Partida">
              <el-input
                v-model="partida.administradorPartida.nome"
                placeholder="Administrador"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="Data de Criação">
              <el-input
                v-model="partida.dataCriacao"
                placeholder="Data de Criação"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12">
            <el-form-item label="Hora de Criação">
              <el-input
                v-model="partida.horaCriacao"
                placeholder="Hora de Criação"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Fechar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import partidaServices from '@/services/partidaService'

export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      partida: {
        campo: { nome: 'Campo 1' },
        time1: { nome: 'Time 1' },
        time2: { nome: 'Time 2' },
        corTime1: 'Azul',
        corTime2: 'Vermelho',
        data: '2024-08-30',
        horaInicio: '14:00',
        horaFim: '16:00',
        administradorPartida: { nome: 'Admin' },
        dataCriacao: '2024-08-27',
        horaCriacao: '10:30',
      },
    }
  },
  async mounted() {
    this.$emit('ativarLoad')
    this.tableData = await partidaServices.findAll()
    this.$emit('desativarLoad')
  },
  methods: {
    addItem() {
      this.$router.push('/novaPartida')
    },
    formatarData(row, column, cellValue) {
      if (!cellValue) return ''
      const [year, month, day] = cellValue.split('-')
      return `${day}/${month}/${year}`
    },
    editItem(item) {
      this.$router.push('/editPartida/' + item.id)
    },
    visualizar(item) {
      this.partida = item
      this.dialogVisible = true
    },
    dados(item) {
      this.$router.push('/dadosPartida/' + item.id)
    },
    async excluir(item) {
      try {
        await this.$confirm(
          'Tem certeza que deseja excluir esta partida?',
          'Excluir Partida',
          {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning',
          }
        )
        this.$emit('ativarLoad')

        await partidaServices.deleteById(item.id)
        this.$message({
          type: 'success',
          message: 'Partida excluída com sucesso!',
        })
        this.tableData = await partidaServices.findAll()
      } catch (error) {
        this.$message({
          type: 'info',
          message: 'A exclusão foi cancelada.',
        })
      } finally {
        this.$emit('desativarLoad')
      }
    },
  },
}
</script>
<style scoped>
.partidas-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilos da tabela (desktop) */
.hidden-sm-and-down {
  display: block;
}

/* Estilos dos cards (mobile) */
.partidas-cards {
  display: none;
  flex-direction: column;
  gap: 16px;
}

.partida-card {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.partida-nome {
  font-size: 16px;
  font-weight: bold;
}

.partida-data {
  font-size: 14px;
  color: #666;
}

.card-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

/* Botão de adicionar */
.add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 50%;
}

/* Responsividade */
@media (max-width: 768px) {
  .hidden-sm-and-down {
    display: none; /* Esconde a tabela em telas pequenas */
  }

  .partidas-cards {
    display: flex; /* Mostra os cards em telas pequenas */
  }
}
</style>
