<template>
  <el-card>
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Carregando dados...</p>
    </div>
    <div v-else>
      <div v-if="statsTime1.time" class="game-stats">
        <div
          ref="statsSection"
          class="stats-section"
          :class="['stats-section', { 'share-mode': compartilhando }]"
        >
          <h2>Estatísticas da Partida</h2>
          <!-- Posse de Bola -->
          <div class="stat-time">
            <div class="time-container">
              <div class="container-logo-time">
                <img
                  class="time-foto"
                  :src="statsTime1.time.foto"
                  v-if="statsTime1.time.foto"
                />
                <img class="time-foto" :src="semLogo1" v-else />
              </div>
              <div class="time-nome">
                <span class="value">{{ statsTime1.time.nome }}</span>
              </div>
            </div>
            <div class="time-container">
              <div class="container-logo-time">
                <img
                  class="time-foto"
                  :src="statsTime2.time.foto"
                  v-if="statsTime2.time.foto"
                />
                <img class="time-foto" :src="semLogo2" v-else />
              </div>
              <div class="time-nome">
                <span class="value">{{ statsTime2.time.nome }}</span>
              </div>
            </div>
          </div>

          <div class="stat">
            <span class="value">
              {{ statsTime1.posseDeBola.split('(')[0] }}
              <div class="percentage">
                ({{ statsTime1.posseDeBola.split('(')[1]?.replace(')', '') }})
              </div>
            </span>
            <label>Posse de Bola</label>
            <span class="value">
              {{ statsTime2.posseDeBola.split('(')[0] }}
              <div class="percentage">
                ({{ statsTime2.posseDeBola.split('(')[1]?.replace(')', '') }})
              </div>
            </span>
          </div>

          <!-- Outras Estatísticas -->
          <div
            class="stat"
            v-for="(stat, index) in filteredStatsLabels"
            :key="index"
          >
            <span class="value">{{ statsTime1[stat.key] }}</span>
            <label>{{ stat.label }}</label>
            <span class="value">{{ statsTime2[stat.key] }}</span>
          </div>
          <el-row>
            <el-col :span="24" class="space-betwen" v-show="compartilhando">
              <img :src="logo" alt="logo aite" style="width: 250px" />
            </el-col>
          </el-row>
        </div>

        <el-row>
          <el-col :span="24" class="space-betwen">
            <el-button type="success" icon="el-icon-share" @click="shareStats"
              >Compartilhar Estatísticas</el-button
            >
          </el-col>
        </el-row>

        <div class="stat">
          <el-row>
            <el-col :span="24" v-for="midia in midias" :key="midia.id">
              <img
                class="img-responsive"
                :src="'data:image/png;base64,' + midia.arquivo"
              />
              <div class="media-actions">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-download"
                  @click="downloadMedia(midia)"
                  >Baixar</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-share"
                  @click="shareMedia(midia)"
                  >Compartilhar</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="cointainer-times">
          <div class="cointainer-time">
            <div class="nome-time">
              {{ statsTime1.time.nome }}
            </div>
            <div
              class="row-dados-jogador-margin"
              v-for="jogador in statsTime1.jogadorPartidaDtos"
              :key="jogador.id"
            >
              <div class="row-dados-jogador">
                <img
                  class="jogador-toto"
                  v-if="jogador.foto"
                  :src="jogador.foto"
                /><img v-else :src="userFoto" class="jogador-toto" />
                <span class="jogador-nome">{{ jogador.nome }}</span>
                <span class="jogador-posicao">{{ jogador.posicao }}</span>
              </div>
              <el-button @click="navegarDadosJogador(jogador.id)" size="mini"
                ><i class="el-icon-data-board"></i
              ></el-button>
            </div>
          </div>
          <div class="cointainer-time">
            <div class="nome-time">
              {{ statsTime2.time.nome }}
            </div>
            <div
              class="row-dados-jogador-margin"
              v-for="jogador in statsTime2.jogadorPartidaDtos"
              :key="jogador.id"
            >
              <div class="row-dados-jogador">
                <img
                  class="jogador-toto"
                  v-if="jogador.foto"
                  :src="jogador.foto"
                /><img v-else :src="userFoto" class="jogador-toto" />
                <span class="jogador-nome">{{ jogador.nome }}</span>
                <span class="jogador-posicao">{{ jogador.posicao }}</span>
              </div>
              <el-button @click="navegarDadosJogador(jogador.id)" size="mini"
                ><i class="el-icon-data-board"></i
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>Dados Não sincronizados</h2>
      </div>
    </div>
  </el-card>
</template>

<script>
import html2canvas from 'html2canvas'
import relatorioPartidaServices from '@/services/relatorioPartidaServices'
import userFoto from '@/assets/user.png'
import semLogo1 from '@/assets/sem-logo-1.png'
import semLogo2 from '@/assets/sem-logo-2.png'
import logo from '@/assets/logo.png'

export default {
  data() {
    return {
      statsTime1: {},
      userFoto: userFoto,
      semLogo1: semLogo1,
      semLogo2: semLogo2,
      isLoading: true,
      compartilhando: false,
      logo,
      midias: [],
      statsTime2: {},
      statsLabels: [
        { key: 'posseDeBola', label: 'Posse de Bola (%)' },
        { key: 'passesCorretos', label: 'Passes Corretos' },
        { key: 'passesRecebidos', label: 'Passes Recebidos' },
        { key: 'passesErrados', label: 'Passes Errados' },
        { key: 'totalChutes', label: 'Total Chutes' },
        { key: 'chutesAoGol', label: 'Chutes ao Gol' },
        { key: 'bolasRecuperadas', label: 'Bolas Recuperadas' },
        { key: 'defesasGoleiro', label: 'Defesas do Goleiro' },
        { key: 'kmPercorridos', label: 'Km Percorridos' },
        { key: 'assistenciasAGols', label: 'Assistências para Gol' },
        { key: 'gols', label: 'Gols' },
        { key: 'escanteios', label: 'Escanteios' },
        { key: 'score', label: 'Score' },
      ],
    }
  },
  computed: {
    filteredStatsLabels() {
      return this.statsLabels.filter((stat) => stat.key !== 'posseDeBola')
    },
  },
  async mounted() {
    try {
      const dados = await relatorioPartidaServices.findByIdPartida(
        this.$route.params.id
      )
      this.statsTime1 = dados[0]
      this.statsTime2 = dados[1]
      this.midias = await relatorioPartidaServices.findMidiasByIdPartida(
        this.$route.params.id
      )
    } catch (error) {
      console.error('Erro ao buscar dados:', error)
    } finally {
      this.isLoading = false // Desativa o carregamento
    }
  },
  methods: {
    navegarDadosJogador(idJogador) {
      this.$router.push(
        '/dadosJogador/' + idJogador + '/' + this.$route.params.id
      )
    },
    isImage(nomeArquivo) {
      const imageExtensions = ['png', 'jpg', 'jpeg', 'gif']
      const fileExtension = nomeArquivo.split('.').pop().toLowerCase()
      return imageExtensions.includes(fileExtension)
    },
    downloadMedia(media) {
      const link = document.createElement('a')
      const mimeType = this.isImage(media.nomeArquivo)
        ? 'image/png'
        : 'video/mp4'
      link.href = `data:${mimeType};base64,${media.arquivo}`
      link.download = media.nomeArquivo
      link.click()
    },
    async shareMedia(media) {
      if (navigator.share) {
        const mimeType = this.isImage(media.nomeArquivo)
          ? 'image/png'
          : 'video/mp4'
        const blob = await fetch(
          `data:${mimeType};base64,${media.arquivo}`
        ).then((res) => res.blob())
        const file = new File([blob], media.nomeArquivo, { type: mimeType })

        try {
          await navigator.share({
            title: 'Confira esta mídia',
            text: 'Estou compartilhando esta mídia com você!',
            files: [file],
          })
          console.log('Compartilhamento realizado com sucesso!')
        } catch (error) {
          console.error('Erro ao compartilhar:', error)
        }
      } else {
        alert('O compartilhamento não é suportado neste dispositivo.')
      }
    },
    async shareStats() {
      this.compartilhando = true
      setTimeout(async () => {
        try {
          const statsSection = this.$refs.statsSection
          const canvas = await html2canvas(statsSection)

          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          )
          const file = new File([blob], 'estatisticas.png', {
            type: 'image/png',
          })

          if (navigator.share) {
            await navigator.share({
              title: 'Estatísticas do Jogador',
              text: 'Confira as estatísticas do jogador!',
              files: [file],
            })
            console.log('Estatísticas compartilhadas com sucesso!')
          } else {
            alert('Compartilhamento não suportado neste dispositivo.')
          }
        } catch (error) {
          console.error('Erro ao compartilhar as estatísticas:', error)
          alert('Não foi possível compartilhar as estatísticas.')
        } finally {
          this.compartilhando = false
        }
      }, 1000)
    },
  },
}
</script>

<style scoped>

.space-betwen {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.stat-time {
  display: flex;
  justify-content: space-around;
}

/* Estilos para a seção de estatísticas */

.stats-section {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stats-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Estilos para as fotos dos times */
.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.time-foto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.time-nome {
  font-size: 18px;
  font-weight: bold;
  color: #444;
  text-align: center;
}

/* Estilos para as estatísticas */
.stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stat label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.stat .value {
  font-size: 16px;
  color: #333;
}

/* Estilos para as mídias */
.midias {
  margin-top: 20px;
}

.img-responsive {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.media-actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Estilos para os jogadores */
.cointainer-times {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.cointainer-time {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nome-time {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  color: #333;
}

.row-dados-jogador {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.row-dados-jogador:last-child {
  border-bottom: none;
}

.jogador-toto {
  width: 60px;
  object-fit: cover;
  border: 2px solid #ddd;
}

.jogador-nome {
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  color: #444;
}

.jogador-posicao {
  font-size: 14px;
  color: #777;
}

/* Estilos para telas maiores (desktop) */
@media (min-width: 768px) {
  .game-stats-container {
    padding: 30px;
  }

  .stats-section {
    padding: 30px;
  }

  .time-foto {
    width: 120px;
  }

  .cointainer-times {
    flex-direction: row;
    gap: 30px;
  }

  .cointainer-time {
    width: 48%;
  }

  .jogador-toto {
    width: 80px;
  }

  .jogador-nome {
    font-size: 18px;
  }

  .jogador-posicao {
    font-size: 16px;
  }
}
</style>
