<template>
  <div class="times-container">
    <!-- Tabela para desktop -->
    <h3 style="text-align: center; margin-bottom: 15px">Lista de Times</h3>
    <el-table :data="tableData" stripe class="hidden-sm-and-down">
      <el-table-column prop="nome" label="Nome"></el-table-column>
      <el-table-column prop="cidade" label="Cidade"></el-table-column>
      <el-table-column prop="estado" label="Estado"></el-table-column>
      <el-table-column prop="pais" label="País"></el-table-column>
      <el-table-column
        prop="admin.nome"
        label="Administrador"
      ></el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" size="mini" type="primary">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            @click="visualizaTime(scope.row)"
            size="mini"
            type="primary"
          >
            <i class="el-icon-view"></i>
          </el-button>
          <el-button
            @click="adicionarJogador(scope.row)"
            size="mini"
            type="primary"
          >
            <i class="el-icon-user"></i>
          </el-button>
          <el-button @click="excluir(scope.row)" size="mini" type="danger">
            <i class="el-icon-close"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Cards para mobile -->
    <div class="times-cards hidden-md-and-up">
      <el-card v-for="item in tableData" :key="item.id" class="time-card">
        <div class="card-content">
          <div class="card-header">
            <span class="time-nome">{{ item.nome }}</span>
            <span class="time-cidade"
              >{{ item.cidade }} - {{ item.estado }}</span
            >
          </div>
          <div class="card-info">
            <span class="time-admin">Admin: {{ item.admin.nome }}</span>
          </div>
          <div class="card-actions">
            <el-button @click="editItem(item)" size="mini" type="primary">
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button @click="visualizaTime(item)" size="mini" type="primary">
              <i class="el-icon-view"></i>
            </el-button>
            <el-button
              @click="adicionarJogador(item)"
              size="mini"
              type="primary"
            >
              <i class="el-icon-user"></i>
            </el-button>
            <el-button @click="excluir(item)" size="mini" type="danger">
              <i class="el-icon-close"></i>
            </el-button>
          </div>
        </div>
      </el-card>
      <el-card v-if="!tableData.length" style="text-align: center">
        <span>Sem dados</span>
      </el-card>
    </div>

    <!-- Botão de adicionar -->
    <el-button
      class="add-button"
      @click="addItem"
      type="primary"
      icon="el-icon-plus"
    ></el-button>

    <!-- Modal de detalhes -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Detalhes do Time"
      width="90%"
    >
      <el-form label-position="top" :model="team" class="team-form">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Nome">
              <el-input
                v-model="team.nome"
                placeholder="Nome"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cidade">
              <el-input
                v-model="team.cidade"
                placeholder="Cidade"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Estado">
              <el-input
                v-model="team.estado"
                placeholder="Estado"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="País">
              <el-input
                v-model="team.pais"
                placeholder="País"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Administrador">
              <el-input
                v-model="team.admin.nome"
                placeholder="Administrador"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Média">
              <el-input
                v-model="team.media"
                placeholder="Média"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Data de Criação">
              <el-input
                v-model="team.dataCriacao"
                placeholder="Data de Criação"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Hora de Criação">
              <el-input
                v-model="team.horaCriacao"
                placeholder="Hora de Criação"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Foto">
              <img
                :src="team.foto"
                alt="Foto do Time"
                style="max-width: 100%; height: auto"
                v-if="team.foto"
              />
              <span v-else>Sem foto</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Fechar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import timeService from '@/services/timeService'

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      team: {
        nome: '',
        cidade: '',
        estado: '',
        pais: '',
        admin: {
          nome: '',
        },
        foto: null,
        media: '',
        dataCriacao: '',
        horaCriacao: '',
      },
    }
  },
  async mounted() {
    this.$emit('ativarLoad')
    this.tableData = await timeService.listarTodos()
    this.$emit('desativarLoad')
  },
  methods: {
    addItem() {
      this.$router.push('/novoTime')
    },
    editItem(item) {
      this.$router.push('/editTime/' + item.id)
    },
    adicionarJogador(item) {
      this.$router.push('/jogadores/' + item.id)
    },
    visualizaTime(item) {
      this.team = item
      this.team.foto = this.team.foto ? this.team.foto : null
      this.dialogVisible = true
    },
    async excluir(item) {
      try {
        await this.$confirm(
          'Tem certeza que deseja excluir este time?',
          'Excluir Time',
          {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning',
          }
        )

        this.$emit('ativarLoad')

        await timeService
          .deletarPorId(item.id)
          .then(async () => {
            this.$message({
              type: 'success',
              message: 'Time excluído com sucesso!',
            })
            this.tableData = await timeService.listarTodos()
          })
          .catch(() =>
            this.$message({
              type: 'warning',
              message:
                'Não é possível excluir, pois este time está sendo usado em uma partida.',
            })
          )
      } catch (error) {
        this.$message({
          type: 'info',
          message: 'A exclusão foi cancelada.',
        })
      } finally {
        this.$emit('desativarLoad')
      }
    },
  },
}
</script>

<style scoped>
.times-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilos da tabela (desktop) */
.hidden-sm-and-down {
  display: block;
}

/* Estilos dos cards (mobile) */
.times-cards {
  display: none;
  flex-direction: column;
  gap: 16px;
}

.time-card {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.time-nome {
  font-size: 16px;
  font-weight: bold;
}

.time-cidade {
  font-size: 14px;
  color: #666;
}

.card-info {
  margin-bottom: 12px;
}

.time-pais {
  font-size: 14px;
  color: #555;
}

.time-admin {
  font-size: 14px;
  color: #555;
}

.time-media {
  font-size: 14px;
  color: #555;
}

.card-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

/* Botão de adicionar */
.add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 50%;
}

/* Responsividade */
@media (max-width: 768px) {
  .hidden-sm-and-down {
    display: none; /* Esconde a tabela em telas pequenas */
  }

  .times-cards {
    display: flex; /* Mostra os cards em telas pequenas */
  }
}
</style>
