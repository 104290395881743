<template>
  <div class="campos-container">
    <!-- Tabela para desktop -->
    <h3 style="text-align: center; margin-bottom: 15px">Lista de Campos</h3>
    <el-table :data="tableData" stripe class="hidden-sm-and-down">
      <el-table-column prop="nome" label="Nome" />
      <el-table-column prop="cidade" label="Cidade" />
      <el-table-column prop="estado" label="Estado" />
      <el-table-column prop="dono" label="Dono" />
      <el-table-column prop="cpfCnpj" label="Cpf/Cnpj" />
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button @click="editItem(scope.row)" size="mini" type="primary">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button
            @click="visualizaCampo(scope.row)"
            size="mini"
            type="primary"
          >
            <i class="el-icon-view"></i>
          </el-button>
          <el-button @click="excluirCampo(scope.row)" size="mini" type="danger">
            <i class="el-icon-close"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Cards para mobile -->
    <div class="campos-cards hidden-md-and-up">
      <el-card v-for="item in tableData" :key="item.id" class="campo-card">
        <div class="card-content">
          <div class="card-header">
            <span class="campo-nome">{{ item.nome }}</span>
            <span class="campo-cidade">{{
              item.cidade + ' - ' + item.estado
            }}</span>
          </div>
          <div class="card-info">
            <span class="campo-dono">{{ item.dono }}</span>
            <span class="campo-cpfCnpj">{{ item.cpfCnpj }}</span>
          </div>
          <div class="card-actions">
            <el-button @click="editItem(item)" size="mini" type="primary">
              <i class="el-icon-edit"></i>
            </el-button>
            <el-button @click="visualizaCampo(item)" size="mini" type="primary">
              <i class="el-icon-view"></i>
            </el-button>
            <el-button @click="excluirCampo(item)" size="mini" type="danger">
              <i class="el-icon-close"></i>
            </el-button>
          </div>
        </div>
      </el-card>
      <el-card v-if="!tableData.length" style="text-align: center">
        <span>Sem dados</span>
      </el-card>
    </div>

    <!-- Botão de adicionar -->
    <el-button
      class="add-button"
      @click="addItem"
      type="primary"
      icon="el-icon-plus"
    ></el-button>

    <!-- Modal de detalhes -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Detalhes do Campo"
      width="90%"
    >
      <el-form label-position="top" :model="location" class="location-form">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Nome">
              <el-input v-model="location.nome" placeholder="Nome" disabled />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Rua">
              <el-input v-model="location.rua" placeholder="Rua" disabled />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Bairro">
              <el-input
                v-model="location.bairro"
                placeholder="Bairro"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cidade">
              <el-input
                v-model="location.cidade"
                placeholder="Cidade"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Estado">
              <el-input
                v-model="location.estado"
                placeholder="Estado"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="País">
              <el-input v-model="location.pais" placeholder="País" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Dono">
              <el-input v-model="location.dono" placeholder="Dono" disabled />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Data de Criação">
              <el-input
                v-model="location.dataCriacao"
                placeholder="Data de Criação"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Hora de Criação">
              <el-input
                v-model="location.horaCriacao"
                placeholder="Hora de Criação"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="Cpf/Cnpj">
              <el-input
                v-model="location.cpfCnpj"
                placeholder="Cpf ou Cnpj"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-form-item label="Foto">
              <img
                :src="location.foto"
                alt="Foto do Local"
                style="max-width: 100%; height: auto"
                v-if="location.foto"
              />
              <span v-else>Sem foto</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Fechar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import campoServices from '@/services/campoService'

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      location: {},
    }
  },
  async mounted() {
    this.$emit('ativarLoad')
    this.tableData = await campoServices.listarTodos()
    this.$emit('desativarLoad')
  },
  methods: {
    addItem() {
      this.$router.push('/novoCampo')
    },
    editItem(item) {
      this.$router.push('/editCampo/' + item.id)
    },
    visualizaCampo(item) {
      this.location = item
      this.dialogVisible = true
    },
    async excluirCampo(item) {
      try {
        await this.$confirm(
          'Tem certeza que deseja excluir este campo?',
          'Excluir Campo',
          {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning',
          }
        )
        this.$emit('ativarLoad')

        await campoServices.deletarPorId(item.id)
        this.$message({
          type: 'success',
          message: 'Campo excluído com sucesso!',
        })
        this.tableData = await campoServices.listarTodos()
      } catch (error) {
        this.$message({
          type: 'info',
          message: 'A exclusão foi cancelada.',
        })
      } finally {
        this.$emit('desativarLoad')
      }
    },
  },
}
</script>
<style scoped>
.campos-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilos da tabela (desktop) */
.hidden-sm-and-down {
  display: block;
}

/* Estilos dos cards (mobile) */
.campos-cards {
  display: none;
  flex-direction: column;
  gap: 16px;
}

.campo-card {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 16px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.campo-nome {
  font-size: 16px;
  font-weight: bold;
}

.campo-cidade {
  font-size: 14px;
  color: #666;
}

.card-info {
  margin-bottom: 12px;
}

.campo-dono {
  font-size: 14px;
  color: #0a0a0a;
  margin-right: 10px;
}

.campo-cpfCnpj {
  font-size: 14px;
  color: #555;
}

.card-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

/* Botão de adicionar */
.add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 50%;
}

/* Responsividade */
@media (max-width: 768px) {
  .hidden-sm-and-down {
    display: none; /* Esconde a tabela em telas pequenas */
  }

  .campos-cards {
    display: flex; /* Mostra os cards em telas pequenas */
  }
}
</style>
