<template>
  <div class="dashboard-container">
    <div class="cards-container">
      <!-- Card para Resumo de Jogos -->
      <div class="card">
        <h2>Melhores Times</h2>
        <div v-for="(time, index) in times" :key="time.id" class="time-card">
          <div class="time-card-foto">
            <img
              :src="time.foto || semLogo2"
              alt="Logo do time"
              class="time-foto"
            />
          </div>
          <div class="time-info">
            <h3>{{ index + 1 }} ° - {{ time.nome }}</h3>
            <p>Cidade: {{ time.cidade }}</p>
            <p>Estado: {{ time.estado }}</p>
            <p>Média de Score: {{ time.mediaScore }}</p>
          </div>
        </div>
      </div>

      <!-- Card para Melhores Jogadores -->
      <div class="card">
        <h2>Melhores Jogadores por Posição</h2>
        <div
          v-for="(jogadores, posicao) in jogadoresPorPosicao"
          :key="posicao"
          class="jogador-section"
        >
          <h3>{{ posicao | capitalize }}</h3>
          <div
            v-for="(jogador, index) in jogadores"
            :key="jogador.jogadorId"
            class="jogador-card"
          >
            <span class="posicao-jogador">{{ index + 1 }}°</span>
            <img
              :src="verificaFoto(jogador.foto)"
              alt="Foto do jogador"
              class="jogador-foto"
            />
            <div class="jogador-info">
              <h4>{{ jogador.nome }}</h4>
              <p>Cidade: {{ jogador.cidade }}</p>
              <p>Estado: {{ jogador.estado }}</p>
              <p>Média de Score: {{ jogador.mediaScore }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import publicoService from '@/services/publicoService'
import userFoto from '@/assets/user.png'
import semLogo2 from '@/assets/sem-logo-2.png'

export default {
  name: 'dashBoard',
  data() {
    return {
      jogadoresPorPosicao: {},
      times: [],
      userFoto,
      semLogo2,
    }
  },
  async mounted() {
    this.$emit('ativarLoad')
    await publicoService.topJogadores().then((d) => (this.jogadoresPorPosicao = d))
    await publicoService.topTimes().then((d) => (this.times = d))
    this.$emit('desativarLoad')
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  methods: {
    verificaFoto(foto) {
      return foto && foto.length > 5 ? foto : userFoto
    },
  },
}
</script>

<style scoped>
.time-card-foto {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Imagem do time */
.time-foto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Imagem do jogador */
.jogador-foto {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  float: left;
}

/* Ajuste para informações de jogadores */
.jogador-info {
  overflow: hidden;
  padding-left: 90px; /* Alinha o texto com a imagem */
}

.dashboard-container {
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Estilos dos cards */
.card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Estilos para cada time */
.time-card,
.jogador-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
  text-align: left;
}

/* Estilos para sessão de jogadores por posição */
.jogador-section {
  margin-top: 20px;
}

.jogador-section h3 {
  text-align: left;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Mobile first */
@media (min-width: 768px) {
  .cards-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .card {
    width: 45%;
  }
}

.posicao-jogador {
  position: absolute;
  font-size: 18px;
  margin-left: -8px;
  margin-top: -5px;
  font-weight: bold;
}
</style>
